<template>
  <article class="page">
    <h2 class="page__heading">{{ page.title }}</h2>
    <section class="page__content post-content" v-html="page.text"></section>
  </article>
</template>

<script>
export default {
  load({ store, route }) {
    return store.pages?.load(route.matched[0].name).then((data) => {
      store.head.title = `FindSourcing – ${data.title}`;
      // TODO: Add custom head.description & head.image
    });
  },
  data() {
    return {};
  },
  computed: {
    page() {
      return this.$store.pages.all[this.$route.name] || {};
    },
  },
};
</script>
