var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "page" }, [
    _c("h2", { staticClass: "page__heading" }, [
      _vm._v(_vm._s(_vm.page.title)),
    ]),
    _vm._v(" "),
    _c("section", {
      staticClass: "page__content post-content",
      domProps: { innerHTML: _vm._s(_vm.page.text) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }